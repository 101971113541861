import "./SettingsForm.scss";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch} from "../state/store";
import {saveNote, selectNoteSlice, setNoteSettings, setSettingsOpen} from "../state/note-slice";
import {Switch} from "../components/Switch";
import {TextInput} from "../components/TextInput";
import classNames from "classnames";
import {Button} from "../components/Button";
import {FormView} from "../components/FormView";

export function SettingsForm() {
    const dispatch = useDispatch() as AppDispatch;
    const store = useSelector(selectNoteSlice);

    const [pinned, setPinned] = useState(store.note.pinned);
    const [encryptionEnabled, setEncryptionEnabled] = useState(store.note.encrypted);
    const [encryptionPassword, setEncryptionPassword] = useState(store.password || "");

    // update state on note change
    useEffect(() => {
        setPinned(store.note.pinned);
        setEncryptionEnabled(store.note.encrypted);
        setEncryptionPassword(store.note.password || "");
    }, [store.noteId, store.note, store.isSettingsOpen]);

    const valid = !encryptionEnabled || encryptionPassword.length > 0;

    const save = async () => {
        dispatch(setNoteSettings({
            pinned,
            password: encryptionEnabled ? encryptionPassword : undefined,
        }));
        await dispatch(saveNote());
        dispatch(setSettingsOpen(false));
    };

    const submitButton = <Button primary disabled={!valid} onClick={save}>SAVE</Button>;

    return <FormView className="SettingsForm"
                     title="Settings"
                     canSubmit={valid}
                     onSubmit={save}
                     onClose={() => dispatch(setSettingsOpen(false))}
                     buttons={submitButton}>
        <div className="pinned">
            <span className="label">Pinned</span>
            <Switch value={pinned} setValue={setPinned}/>
        </div>
        <div className="encryption">
            <span className="label">Encryption</span>
            <Switch value={encryptionEnabled} setValue={setEncryptionEnabled}/>
        </div>
        <div className={classNames("encryption-password", {visible: encryptionEnabled})}>
            <input className="username" type="text" autoComplete="username" value={store.noteId} readOnly/>
            <TextInput type="password"
                       placeholder="Password"
                       autoComplete="new-password"
                       value={encryptionPassword}
                       onChange={e => setEncryptionPassword(e.target.value)}/>
        </div>
    </FormView>;
}