import "./NotePasswordForm.scss";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch} from "../state/store";
import React, {useState} from "react";
import {
    loadNote,
    selectNoteSlice,
    setNotePassword,
    setNotePasswordPromptOpen,
} from "../state/note-slice";
import {TextInput} from "../components/TextInput";
import {FormView} from "../components/FormView";
import {isAppError} from "../util/error";
import {Button} from "../components/Button";

export function NotePasswordForm() {
    const dispatch = useDispatch() as AppDispatch;
    const store = useSelector(selectNoteSlice);

    const [password, setPassword] = useState(store.password || "");
    const [error, setError] = useState("");
    const loading = store.noteStatus === "loading";

    const onSubmit = async () => {
        dispatch(setNotePassword(password));
        const result = await dispatch(loadNote());
        const payload = result.payload;
        if (result.type === "note/loadNote/fulfilled") {
            dispatch(setNotePasswordPromptOpen(false));
        } else if (result.type === "note/loadNote/rejected" && isAppError(payload)) {
            setError(payload.message);
        }
    };

    const canSubmit = !loading && password.length > 0;
    const submitButton = <Button primary disabled={!canSubmit} onClick={onSubmit}>OK</Button>;

    return <FormView className="NotePasswordForm"
                     title="Password Required"
                     canSubmit={canSubmit}
                     onSubmit={onSubmit}
                     onClose={() => dispatch(setNotePasswordPromptOpen(false))}
                     buttons={submitButton}>
        <div>A password is required to view or edit this note.</div>
        <input className="username" type="text" autoComplete="username" value={store.noteId} readOnly/>
        <TextInput type="password"
                   autoComplete="current-password"
                   placeholder="Password"
                   value={password}
                   onChange={e => setPassword(e.target.value)}
                   disabled={loading}/>
        {error && <div className="error">{error}</div>}
    </FormView>;
}