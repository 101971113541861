import "./TileButton.scss";
import {HTMLAttributes, useRef} from "react";
import classNames from "classnames";
import {useRipple} from "../util/ripple";

export interface TileButtonProps extends HTMLAttributes<HTMLDivElement> {
    disabled?: boolean,
}

export function TileButton(props: TileButtonProps) {
    const {disabled, ...divProps} = props;
    const ref = useRef<HTMLDivElement>(null);
    const ripple = useRipple(ref);
    return <div {...divProps}
                ref={ref}
                className={classNames("TileButton", {disabled}, props.className)}
                onClick={disabled ? undefined : props.onClick}>
        {divProps.children}
        {!disabled && ripple}
    </div>;
}