import "./NoteContentInput.scss";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch} from "../../state/store";
import {selectIsAuthorized, selectNoteSlice, setContent} from "../../state/note-slice";

export function NoteContentInput() {
    const dispatch = useDispatch() as AppDispatch;
    const store = useSelector(selectNoteSlice);
    const isAuthorized = useSelector(selectIsAuthorized);

    const onContentChange = (newContent: string) => {
        dispatch(setContent(newContent));
    };

    return <textarea
        className="NoteContentInput"
        disabled={!isAuthorized}
        autoFocus={isAuthorized && store.isNoteLoaded}
        value={store.note.content}
        onChange={e => onContentChange(e.currentTarget.value)}
        spellCheck={false}
    />;
}
