import "./DeleteNoteModal.scss";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch} from "../state/store";
import React from "react";
import {deleteNote, selectNoteSlice, setDeleteNoteConfirmationOpen,} from "../state/note-slice";
import {FormView} from "../components/FormView";
import {Button} from "../components/Button";
import {goToNote, newNoteId} from "../util/util";
import {useNavigate} from "react-router-dom";

export function DeleteNoteModal() {
    const navigate = useNavigate();
    const dispatch = useDispatch() as AppDispatch;
    const store = useSelector(selectNoteSlice);

    const onSubmit = async () => {
        dispatch(setDeleteNoteConfirmationOpen(false));
        await dispatch(deleteNote());
        await goToNote(navigate, dispatch, false, store.space, newNoteId());
    };

    const submitButton = <Button primary onClick={onSubmit}>DELETE</Button>;
    const cancelButton = <Button onClick={() => dispatch(setDeleteNoteConfirmationOpen(false))}>CANCEL</Button>;

    return <FormView className="DeleteNoteModal"
                     title="Confirm"
                     onClose={() => dispatch(setDeleteNoteConfirmationOpen(false))}
                     buttons={<>{cancelButton}{submitButton}</>}>
        <div>Are you sure you wish to delete this note?</div>
    </FormView>;
}