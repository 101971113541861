import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {enderpad, Note, NoteSummary, SaveNoteRequest, Session} from "../api";
import {RootState} from "./store";
import {AppError, interpretError} from "../util/error";
import {cloneDeep} from "lodash";
import {isDateTimeInPast, isNewNote, newNoteId} from "../util/util";
import {DEFAULT_NOTE_NAME} from "../constants";

export interface EditingNote {
    name: string,
    folder?: string,
    content: string,
    encrypted: boolean,
    password?: string,
    pinned: boolean,
    version: number,
}

export interface NoteSettings {
    pinned: boolean,
    password?: string,
}

export interface NoteSlice {
    space: string,
    noteId: string,
    note: EditingNote,
    password: string | undefined,
    notes: NoteSummary[] | undefined,
    isNoteLoaded: boolean,
    isNoteSaved: boolean,
    noteStatus: "idle" | "loading" | "success" | "failed",
    noteError: AppError | undefined,
    isLoginOpen: boolean,
    isMenuOpen: boolean,
    isSettingsOpen: boolean,
    isNotePasswordPromptOpen: boolean,
    isDeleteNoteConfirmationOpen: boolean,
    loginStatus: "idle" | "loading" | "success" | "failed",
    loginError: AppError | undefined,
    session: Session | undefined,
}

const savedSessionStr = localStorage.getItem("session");
let savedSession: Session | undefined = undefined;
if (savedSessionStr) {
    try {
        savedSession = JSON.parse(savedSessionStr) as Session;
    } catch (e) {}
}

const initialState: NoteSlice = {
    space: "public",
    noteId: newNoteId(),
    note: {
        name: "",
        content: "",
        encrypted: false,
        pinned: false,
        version: 1,
        password: undefined,
    },
    password: undefined,
    notes: undefined,
    isNoteLoaded: false,
    isNoteSaved: false,
    noteStatus: "idle",
    noteError: undefined,
    isLoginOpen: false,
    isMenuOpen: false,
    isSettingsOpen: false,
    isNotePasswordPromptOpen: false,
    isDeleteNoteConfirmationOpen: false,
    loginStatus: "idle",
    loginError: undefined,
    session: savedSession,
};

export const login = createAsyncThunk(
    "session/login",
    async ({username, password}: {username: string, password: string}, thunkAPI) => {
        try {
            return await enderpad.login(username, password);
        } catch (err: any) {
            return thunkAPI.rejectWithValue(interpretError(err));
        }
    },
);

export const logout = createAsyncThunk(
    "session/logout",
    async (payload, thunkAPI) => {
        try {
            const session = (thunkAPI.getState() as RootState).note.session;
            if (!session) return;
            return await enderpad.logout(session.token);
        } catch (err: any) {
            return thunkAPI.rejectWithValue(interpretError(err));
        }
    },
);

export const loadSpace = createAsyncThunk(
    "note/loadSpace",
    async (payload, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        try {
            return await enderpad.getNotes(state.note.session?.token, state.note.space);
        } catch (err: any) {
            return thunkAPI.rejectWithValue(interpretError(err));
        }
    }
);

export const loadNote = createAsyncThunk(
    "note/loadNote",
    async (payload, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        try {
            let notes = state.note.notes;
            if (!notes) {
                console.warn("Loading notes before active note");
                notes = await enderpad.getNotes(state.note.session?.token, state.note.space)
            }

            let note: Note | undefined = undefined;
            if (notes.some(n => n.id === state.note.noteId)) {
                note = await enderpad.getNote(state.note.session?.token, state.note.space, state.note.noteId, state.note.password);
                console.debug("Loaded note " + state.note.noteId);
            }
            return note;
        } catch (err: any) {
            return thunkAPI.rejectWithValue(interpretError(err));
        }
    }
);

export const saveNote = createAsyncThunk(
    "note/saveNote",
    async (payload, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        const {space, noteId, note, password} = state.note;
        try {
            const response = await enderpad.saveNote(state.note.session?.token, space, noteId, {
                name: note.name,
                folder: note.folder,
                content: note.content,
                password: note.password || null,
                pinned: note.pinned,
                version: note.version,
            }, password);
            console.debug("Saved note " + noteId);
            resetSaveBeforeUnload();
            return response;
        } catch (err) {
            return thunkAPI.rejectWithValue(interpretError(err));
        }
    });

export const deleteNote = createAsyncThunk(
    "note/deleteNote",
    async (payload, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        const {space, noteId} = state.note;
        try {
            const response = await enderpad.deleteNote(state.note.session?.token, space, noteId, state.note.password);
            console.debug("Deleted note " + noteId);
            resetSaveBeforeUnload();
            return response;
        } catch (err) {
            return thunkAPI.rejectWithValue(interpretError(err));
        }
    });

export const shareNote = createAsyncThunk(
    "note/shareNote",
    async (payload, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        const {space, noteId} = state.note;
        try {
            const response = await enderpad.shareNote(state.note.session?.token, space, noteId, state.note.password);
            console.debug("Shared note " + noteId);
            return response;
        } catch (err) {
            return thunkAPI.rejectWithValue(interpretError(err));
        }
    });

export const noteSlice = createSlice({
    name: "note",
    initialState,
    reducers: {
        setLoginOpen: (state, action: PayloadAction<boolean>) => {
            state.isLoginOpen = action.payload;
        },
        invalidateSession: state => {
            if (state.session) {
                state.session.expires = state.session.created;
            }
            localStorage.setItem("session", JSON.stringify(state.session));
        },
        setMenuOpen: (state, action: PayloadAction<boolean>) => {
            state.isMenuOpen = action.payload;
        },
        setSettingsOpen: (state, action: PayloadAction<boolean>) => {
            state.isSettingsOpen = action.payload;
        },
        setNoteSettings: (state, action: PayloadAction<NoteSettings>) => {
            state.note.pinned = action.payload.pinned;
            state.note.encrypted = action.payload.password != null;
            state.note.password = action.payload.password;
            state.isNoteSaved = false;
        },
        setNotePasswordPromptOpen: (state, action: PayloadAction<boolean>) => {
            state.isNotePasswordPromptOpen = action.payload;
        },
        setDeleteNoteConfirmationOpen: (state, action: PayloadAction<boolean>) => {
            state.isDeleteNoteConfirmationOpen = action.payload;
        },
        setNotePassword: (state, action: PayloadAction<string | undefined>) => {
            state.password = action.payload;
        },
        setActiveNote: (state, action: PayloadAction<{space: string, noteId: string}>) => {
            const {space, noteId} = action.payload;
            const spaceChanged = state.space !== space;
            if (spaceChanged || state.noteId !== noteId) {
                // reset active note state
                state.space = space;
                state.noteId = noteId;
                state.note = cloneDeep(initialState.note);
                state.isNoteLoaded = false;
                state.isNoteSaved = false;
                state.noteStatus = "idle";
                state.noteError = undefined;

                // reset notes if space changed, otherwise pre-populate note state if new id is present in notes list
                if (spaceChanged) state.notes = undefined;
                else writeSummaryToEditingNoteIfPresent(state.notes, noteId, state.note);

                // remember last visited note
                setLastSeenNote(space, noteId);
            }
        },
        setName: (state, action: PayloadAction<string>) => {
            state.note.name = action.payload;
            state.isNoteSaved = false;
        },
        setContent: (state, action: PayloadAction<string>) => {
            state.note.content = action.payload;
            state.isNoteSaved = false;
        },
        armSaveOnUnload: state => {
            const {session, space, noteId, note, password} = state;
            if (!space || !noteId) return;
            setSaveBeforeUnload({
                token: session?.token,
                space: space,
                noteId: noteId,
                request: {
                    name: note.name,
                    content: note.content,
                    version: note.version,
                },
                password: password,
            });
        },
    },
    extraReducers: builder => {
        // login
        builder.addCase(login.pending, (state) => {state.loginStatus = "loading";});
        builder.addCase(login.fulfilled, (state, action) => {
            state.loginStatus = "success";
            state.session = action.payload;
            state.isLoginOpen = false;
            localStorage.setItem("session", JSON.stringify(action.payload));
        });
        builder.addCase(login.rejected, (state, action) => {
            state.loginStatus = "failed";
            state.loginError = action.payload as AppError;
        });

        // logout
        builder.addCase(logout.fulfilled, (state) => {
            state.session = undefined;
            state.isLoginOpen = false;
            localStorage.removeItem("session");
        });

        // load space
        builder.addCase(loadSpace.fulfilled, (state, action) => {
            state.notes = action.payload;
            // pre-populate editing note state if present
            writeSummaryToEditingNoteIfPresent(state.notes, state.noteId, state.note);
        });

        // load note
        builder.addCase(loadNote.pending, state => {state.noteStatus = "loading";});
        builder.addCase(loadNote.fulfilled, (state, action) => {
            state.noteStatus = "success";
            const note = action.payload;
            if (note) {
                state.note = {
                    name: note.name,
                    folder: note.folder,
                    content: note.content,
                    encrypted: note.encrypted,
                    password: state.password,
                    pinned: note.pinned,
                    version: note.version,
                };
                state.isNoteLoaded = true;
                state.isNoteSaved = true;
            } else {
                state.note = {
                    name: DEFAULT_NOTE_NAME,
                    folder: undefined,
                    content: "",
                    encrypted: false,
                    pinned: false,
                    version: 1,
                };
                state.isNoteLoaded = true;
                state.isNoteSaved = false;
            }
        });
        builder.addCase(loadNote.rejected, (state, action) => {
            state.noteStatus = "failed";
            state.noteError = action.payload as AppError;
        });

        // save note
        builder.addCase(saveNote.fulfilled, (state, action) => {
            if (action.payload) {
                state.note.version = action.payload.version;
                state.password = state.note.password;
                state.isNoteLoaded = true;
                state.isNoteSaved = true;

                // update notes
                if (state.notes) {
                    const existingNoteIndex = state.notes.findIndex(n => n.id === action.payload.id);
                    if (existingNoteIndex !== -1) {
                        state.notes[existingNoteIndex] = action.payload;
                    } else {
                        state.notes.unshift(action.payload);
                    }
                }
            }
        });

        // delete note
        builder.addCase(deleteNote.pending, state => {state.noteStatus = "loading";});
        builder.addCase(deleteNote.fulfilled, state => {
            state.noteStatus = "success";
            // update notes
            if (state.notes) {
                const index = state.notes.findIndex(n => n.id === state.noteId);
                if (index !== -1) {
                    state.notes.splice(index, 1);
                }
            }
        });
        builder.addCase(deleteNote.rejected, (state, action) => {
            state.noteStatus = "failed";
            state.noteError = action.payload as AppError;
        });

        // share note
        builder.addCase(shareNote.pending, state => {state.noteStatus = "loading";});
        builder.addCase(shareNote.fulfilled, state => {state.noteStatus = "success";});
        builder.addCase(shareNote.rejected, (state, action) => {
            state.noteStatus = "failed";
            state.noteError = action.payload as AppError;
        });
    },
});

export const {
    setLoginOpen, invalidateSession, setMenuOpen, setSettingsOpen, setNoteSettings, setNotePasswordPromptOpen,
    setDeleteNoteConfirmationOpen, setNotePassword, setActiveNote, setName, setContent, armSaveOnUnload,
} = noteSlice.actions;

export const selectNoteSlice = (state: RootState) => state.note;
export const selectIsNotePersisted = (state: RootState) => state.note.notes && state.note.notes.some(n => n.id === state.note.noteId);
export const selectShouldSave = (state: RootState) =>
    state.note.isNoteLoaded &&
    !state.note.isNoteSaved &&
    !isNewNote(state.note.note.name, state.note.note.content);
export const selectIsAuthorized = (state: RootState) => {
    return state.note.space === "public" || (
        state.note?.session != null &&
        state.note.space === state.note.session.username &&
        !isDateTimeInPast(state.note.session.expires)
    );
}

export const noteReducer = noteSlice.reducer;

export function getLastSeenNote(space: string): string | undefined {
    return localStorage.getItem("last-seen." + space) || undefined;
}

function setLastSeenNote(space: string, noteId: string) {
    localStorage.setItem("last-seen." + space, noteId);
}

function writeSummaryToEditingNoteIfPresent(notes: NoteSummary[] | undefined, id: string, editing: EditingNote) {
    const summary = notes?.find(n => n.id === id);
    if (summary) {
        editing.name = summary.name;
        editing.version = summary.version;
        editing.pinned = summary.pinned;
        editing.encrypted = summary.encrypted;
    }
}

interface SaveNoteParams {
    token?: string,
    space: string,
    noteId: string,
    request: SaveNoteRequest,
    password?: string,
}

function setSaveBeforeUnload(params: SaveNoteParams) {
    window.onbeforeunload = function () {
        // noinspection JSIgnoredPromiseFromCall
        enderpad.saveNote(params.token, params.space, params.noteId, params.request, params.password);
    }
}

function resetSaveBeforeUnload() {
    window.onbeforeunload = null;
}

