import {v4 as uuidv4} from 'uuid';
import {Session} from "../api";
import {NavigateFunction} from "react-router-dom";
import {AppDispatch} from "../state/store";
import {saveNote, setActiveNote} from "../state/note-slice";
import {DEFAULT_NOTE_NAME} from "../constants";

export async function goToNote(
    navigate: NavigateFunction,
    dispatch: AppDispatch,
    shouldSave: boolean,
    space: string,
    noteId?: string,
) {
    if (shouldSave) {
        await dispatch(saveNote());
    }
    const targetNoteId = noteId || newNoteId();
    dispatch(setActiveNote({space, noteId: targetNoteId}));
    navigate(getNoteUrl(space, targetNoteId));
}

export function isNewNote(name: string, content: string): boolean {
    return (!name || name === DEFAULT_NOTE_NAME) && content.length === 0;
}

export function newNoteId(): string {
    return uuidv4();
}

export function newNoteUrl(space: string): string {
    return getNoteUrl(space, newNoteId());
}

export function getNoteUrl(space: string, noteId: string): string {
    return `/note/${space}/${noteId}`;
}

export function compareDateTimeStr(dt1str: string, dt2str: string): number {
    const dt1 = new Date(dt1str).valueOf();
    const dt2 = new Date(dt2str).valueOf();
    return dt1 - dt2;
}

export function isDateTimeInPast(dateTimeStr: string): boolean {
    return Date.now() > new Date(dateTimeStr).valueOf();
}

export function isAuthOk(space: string, session?: Session): boolean {
    if (!session) return space === "public";
    const isSessionValid = !isDateTimeInPast(session.expires);
    return space === "public" || (isSessionValid && session.username === space);
}
