import "./NotePage.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/free-solid-svg-icons/faBars";
import {useParams} from "react-router-dom";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    armSaveOnUnload,
    loadNote,
    loadSpace,
    saveNote,
    selectIsAuthorized,
    selectNoteSlice,
    selectShouldSave,
    setActiveNote,
    setContent,
    setDeleteNoteConfirmationOpen,
    setLoginOpen,
    setMenuOpen,
    setName,
    setNotePasswordPromptOpen,
    setSettingsOpen
} from "../../state/note-slice";
import {AppDispatch} from "../../state/store";
import {SpinnerOverlay} from "../../components/SpinnerOverlay";
import {ErrorOverlay} from "../../components/ErrorOverlay";
import Menu from "./Menu";
import {NoteNameInput} from "./NoteNameInput";
import {NoteContentInput} from "./NoteContentInput";
import {isNewNote} from "../../util/util";
import {LoginForm} from "../../views/LoginForm";
import {addListener, isAnyOf} from "@reduxjs/toolkit";
import {Modal} from "../../components/Modal";
import {SettingsForm} from "../../views/SettingsForm";
import {NotePasswordForm} from "../../views/NotePasswordForm";
import {DeleteNoteModal} from "../../views/DeleteNoteModal";
import {NoteActions} from "./NoteActions";
import {MobileMenuContainer} from "./MobileMenuContainer";
import {Button} from "../../components/Button";
import {ErrorCode} from "../../util/error";

export default function NotePage() {
    // Important: params don't always update, can't rely on it. Only use it for setting initial values.
    const params = useParams() as {space: string, noteId: string};

    const dispatch = useDispatch() as AppDispatch;

    const store = useSelector(selectNoteSlice);
    const isAuthorized = useSelector(selectIsAuthorized);
    const shouldSave = useSelector(selectShouldSave);

    // initialize
    useEffect(() => {
        const {space, noteId} = params;
        if (!space || !noteId) throw new Error("space or noteId are not defined");
        dispatch(setActiveNote({space, noteId}));
        // eslint-disable-next-line
    }, [dispatch]);

    // handle auth and loading note
    const notesLoaded = !!store.notes;
    useEffect(() => {
        if (!isAuthorized) {
            // we need a login
            dispatch(setLoginOpen(true));
            return
        }

        // load if necessary
        if (!notesLoaded) {
            dispatch(loadSpace());
        } else if (!store.isNoteLoaded) {
            dispatch(loadNote());
        }
    }, [dispatch, isAuthorized, notesLoaded, store.noteId, store.isNoteLoaded]);

    // save periodically
    useEffect(() => {
        let saving = false;
        const intervalId = setInterval(async () => {
            if (isAuthorized && shouldSave && !isNewNote(store.note.name, store.note.content) && !saving) {
                saving = true;
                try {
                    await dispatch(saveNote());
                } finally {
                    saving = false;
                }
            }
        }, 5000);
        return () => clearInterval(intervalId);
    }, [dispatch, isAuthorized, shouldSave, store.note.name, store.note.content]);

    // arm save whenever something changes
    useEffect(() => dispatch(addListener({
        matcher: isAnyOf(setName, setContent),
        effect: () => {
            dispatch(armSaveOnUnload());
        },
    })), [dispatch]);

    // special error handling
    let showError = store.noteStatus === "failed";
    let noteError: React.ReactNode = store.noteError?.message;
    if ([ErrorCode.PasswordRequired, ErrorCode.PasswordIncorrect].includes(store.noteError?.errorCode!)) {
        noteError = <Button onClick={() => dispatch(setNotePasswordPromptOpen(true))}>PASSWORD REQUIRED</Button>;
    }

    return <div className="NotePage">
        <div className="content">
            <div className="content-header">
                <NoteNameInput/>
                <FontAwesomeIcon
                    icon={faBars}
                    size={"2x"}
                    className="menu-icon"
                    onClick={() => dispatch(setMenuOpen(true))}
                />
                <NoteActions/>
            </div>
            <div className="content-body">
                <NoteContentInput/>
                {store.noteStatus === "loading" && <SpinnerOverlay/>}
                {showError && <ErrorOverlay>{noteError || "Unable to load"}</ErrorOverlay>}
            </div>
            <div className="content-footer">
                <NoteActions/>
            </div>
            <Menu/>
            <MobileMenuContainer/>
        </div>
        <Modal open={store.isLoginOpen}>
            <LoginForm/>
        </Modal>
        <Modal open={store.isSettingsOpen} onClickOutside={() => dispatch(setSettingsOpen(false))}>
            <SettingsForm/>
        </Modal>
        <Modal open={store.isNotePasswordPromptOpen} onClickOutside={() => dispatch(setNotePasswordPromptOpen(false))}>
            <NotePasswordForm/>
        </Modal>
        <Modal open={store.isDeleteNoteConfirmationOpen} onClickOutside={() => dispatch(setDeleteNoteConfirmationOpen(false))}>
            <DeleteNoteModal/>
        </Modal>
    </div>;
}
