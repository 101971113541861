import {configureStore, createListenerMiddleware, Middleware, MiddlewareAPI} from "@reduxjs/toolkit";
import {invalidateSession, noteReducer, setLoginOpen, setNotePasswordPromptOpen} from "./note-slice";
import {ErrorCode, isAppError} from "../util/error";
import {toast} from "react-toastify";

export const listenerMiddleware = createListenerMiddleware();

const appErrorHandlerMiddleware: Middleware = (api: MiddlewareAPI) => next => action => {
    const payload = action.payload;
    if (isAppError(payload)) {
        if (payload.errorCode === ErrorCode.Unauthorized) {
            console.debug("Got Unauthorized error from API, invalidating session");
            api.dispatch(invalidateSession());
            api.dispatch(setLoginOpen(true));
        } else if ([ErrorCode.PasswordRequired, ErrorCode.PasswordIncorrect].includes(payload.errorCode!)) {
            api.dispatch(setNotePasswordPromptOpen(true));
        } else if ([ErrorCode.InvalidLogin].includes(payload.errorCode!)) {
            // ignore
        } else {
            toast.error(payload.message, {toastId: payload.errorCode || payload.message, autoClose: false});
        }
    }
    return next(action);
};

export const store = configureStore({
    reducer: {
        note: noteReducer,
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware()
            .prepend(appErrorHandlerMiddleware)
            .prepend(listenerMiddleware.middleware)
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;