import "./App.scss";
import React from "react";
import {createHashRouter, Navigate, RouterProvider} from "react-router-dom";
import NotFoundPage from "./pages/NotFoundPage";
import ErrorPage from "./pages/ErrorPage";
import NotePage from "./pages/note-page/NotePage";
import {newNoteUrl} from "./util/util";
import {useSelector} from "react-redux";
import {selectNoteSlice} from "./state/note-slice";
import {SharePage} from "./pages/SharePage";

export default function App() {
    const store = useSelector(selectNoteSlice);

    const router = createHashRouter([
        {
            path: "/note/:space/:noteId",
            element: <NotePage/>,
            errorElement: <ErrorPage/>,
        }, {
            path: "/share/:shareCode",
            element: <SharePage/>,
            errorElement: <ErrorPage/>,
        }, {
            path: "/",
            element: <Navigate to={newNoteUrl(store.session?.username || "public")} replace={true}/>,
        }, {
            path: "*",
            element: <NotFoundPage/>,
        },
    ]);

    return <RouterProvider router={router}/>;
}
