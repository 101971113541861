import "./NoteNameInput.scss";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch} from "../../state/store";
import {selectIsAuthorized, selectNoteSlice, setName} from "../../state/note-slice";
import sanitize from "sanitize-html";

export function NoteNameInput() {
    const dispatch = useDispatch() as AppDispatch;
    const store = useSelector(selectNoteSlice);
    const isAuthorized = useSelector(selectIsAuthorized);

    const [controlledValue, setControlledValue] = useState(store.note.name);
    const [isFocused, setFocused] = useState(false);
    useEffect(() => {
        if (!isFocused) {
            setControlledValue(store.note.name);
        }
    }, [store.note.name, isFocused]);

    const onNameChange = (newName: string | null) => {
        dispatch(setName(sanitize(newName || "")));
    };

    return <div
        className="NoteNameInput"
        title={store.note.name}
        contentEditable={isAuthorized && store.isNoteLoaded}
        onInput={e => onNameChange(e.currentTarget.textContent)}
        onFocus={() => setFocused(true)}
        onBlur={e => {
            setFocused(false);
            e.currentTarget.scrollLeft = 0;
            onNameChange(e.currentTarget.textContent);
        }}
        dangerouslySetInnerHTML={{__html: sanitize(controlledValue, {allowedTags: [], allowedAttributes: {}, disallowedTagsMode: "escape"})}}
        spellCheck={false}
    />;
}