import "./index.scss";
import "react-toastify/dist/ReactToastify.css";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import {Provider} from "react-redux";
import {store} from "./state/store";
import {ToastContainer} from "react-toastify";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <App/>
            <ToastContainer
                className="ToastContainer"
                position="bottom-right"
                hideProgressBar={true}
                closeOnClick={true}
                theme="dark"
            />
        </Provider>
    </React.StrictMode>
);
