import axios from "axios";
import {Base64} from "js-base64";

export interface Session {
    token: string,
    username: string,
    expires: string,
    created: string,
}

export interface NoteSummary {
    id: string,
    name: string,
    folder?: string,
    encrypted: boolean,
    pinned: boolean,
    version: number,
    created: string,
    updated: string,
}

export interface Note {
    space: string,
    id: string,
    name: string,
    folder?: string,
    content: string,
    encrypted: boolean,
    pinned: boolean,
    version: number,
    created: string,
    updated: string,
}

export interface SaveNoteRequest {
    name?: string,
    folder?: string,
    content?: string,
    password?: string | null,
    pinned?: boolean,
    version: number,
}

export interface SharedNote {
    content: string,
    expires: string,
    created: string,
}

export class EnderpadApi {

    private readonly baseUrl: string;

    constructor() {
        if (process.env.NODE_ENV === "development") {
            console.log("Using development api url");
            this.baseUrl = "http://localhost:8080/api";
        } else {
            this.baseUrl = "/api";
        }
    }

    async login(username: string, password: string): Promise<Session> {
        const response = await axios.post<Session>(
            this.baseUrl + "/login",
            `username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`,
            {headers: {"Content-Type": "application/x-www-form-urlencoded"}}
        );
        return response.data;
    }

    async logout(token: string): Promise<void> {
        await axios.post<Session>(
            this.baseUrl + "/logout",
            "",
            {headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Authorization": token,
            }},
        );
    }

    async getNotes(token: string | undefined, space: string): Promise<NoteSummary[]> {
        const response = await axios.get<NoteSummary[]>(
            this.baseUrl + "/space/" + space + "/notes",
            {headers: {"Authorization": token}},
        );
        return response.data;
    }

    async getNote(token: string | undefined, space: string, id: string, password: string | undefined): Promise<Note> {
        const response = await axios.get<Note>(
            this.baseUrl + "/space/" + space + "/notes/" + id,
            {headers: {
                "Authorization": token,
                "Note-Password": password,
            }},
        );
        const note = response.data;
        note.content = Base64.decode(note.content);
        return response.data;
    }

    async saveNote(token: string | undefined, space: string, id: string, note: SaveNoteRequest, password: string | undefined): Promise<NoteSummary> {
        const request = {...note};
        if (request.content) request.content = Base64.encode(request.content);
        const response = await axios.put<NoteSummary>(
            this.baseUrl + "/space/" + space + "/notes/" + id,
            request,
            {headers: {
                "Authorization": token,
                "Note-Password": password,
            }},
        );
        return response.data;
    }

    async deleteNote(token: string | undefined, space: string, id: string, password: string | undefined): Promise<void> {
        await axios.delete(
            this.baseUrl + "/space/" + space + "/notes/" + id,
            {headers: {
                "Authorization": token,
                "Note-Password": password,
            }},
        );
    }

    async shareNote(token: string | undefined, space: string, id: string, password: string | undefined): Promise<string> {
        const response = await axios.post<string>(
            this.baseUrl + "/space/" + space + "/notes/" + id + "/share", {ttl_minutes: 5},
            {headers: {
                "Authorization": token,
                "Note-Password": password,
            }},
        );
        return response.data;
    }

    async getSharedNote(shareCode: string): Promise<SharedNote> {
        const response = await axios.get<SharedNote>(this.baseUrl + "/share/" + shareCode);
        return response.data;
    }

}

export const enderpad = new EnderpadApi();