import "./LoginForm.scss";
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch} from "../state/store";
import {TextInput} from "../components/TextInput";
import {Button} from "../components/Button";
import {Session} from "../api";
import {SpinnerOverlay} from "../components/SpinnerOverlay";
import {useNavigate} from "react-router-dom";
import {goToNote, newNoteId} from "../util/util";
import {setLoginOpen, login, selectNoteSlice, selectShouldSave, getLastSeenNote} from "../state/note-slice";
import {FormView} from "../components/FormView";

export function LoginForm() {
    const navigate = useNavigate();
    const dispatch = useDispatch() as AppDispatch;

    const store = useSelector(selectNoteSlice);
    const shouldSave = useSelector(selectShouldSave);

    const [username, setUsername] = useState(store.session?.username || "");
    const [password, setPassword] = useState("");

    const doLogin = async () => {
        const result = await dispatch(login({username, password}));
        if (result.type === "login/fulfilled") {
            const session = result.payload as Session;
            if (session.username !== store.space) {
                await goToNote(navigate, dispatch, shouldSave, session.username, getLastSeenNote(session.username) || newNoteId());
            }
        }
    };

    const onUsePublic = () => {
        dispatch(setLoginOpen(false));
        if (store.space !== "public") {
            // noinspection JSIgnoredPromiseFromCall
            goToNote(navigate, dispatch, shouldSave, "public");
        }
    };

    const usePublicButton = <Button onClick={onUsePublic}>USE PUBLIC</Button>;
    const submitButton = <Button primary onClick={doLogin}>LOGIN</Button>;

    return <FormView className="LoginForm"
                     title="Login"
                     onSubmit={doLogin}
                     onClose={onUsePublic}
                     buttons={<>{usePublicButton}{submitButton}</>}>
        <TextInput type="text"
                   autoComplete="username"
                   spellCheck={false}
                   placeholder="Username"
                   value={username}
                   onChange={e => setUsername(e.target.value)}/>
        <TextInput type="password"
                   autoComplete="current-password"
                   spellCheck={false}
                   placeholder="Password"
                   value={password}
                   onChange={e => setPassword(e.target.value)}/>
        {store.loginStatus === "loading" && <SpinnerOverlay/>}
        {store.loginError && <div className="error">{store.loginError.message}</div>}
    </FormView>;
}