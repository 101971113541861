import {isAxiosError} from "axios";

export enum ErrorCode {
    InvalidId = "InvalidId",
    InvalidFolder = "InvalidFolder",
    NotFound = "NotFound",
    NoteDeleted = "NoteDeleted",
    PasswordRequired = "PasswordRequired",
    PasswordIncorrect = "PasswordIncorrect",
    VersionMismatch = "VersionMismatch",
    Unauthorized = "Unauthorized",
    InvalidLogin = "InvalidLogin",
    Forbidden = "Forbidden",
    BadRequest = "BadRequest",
    InternalServerError = "InternalServerError",
}

function getErrorCode(errorCode: string): ErrorCode | undefined {
    for (let [str, code] of Object.entries(ErrorCode)) {
        if (str === errorCode) {
            return code;
        }
    }
    return undefined;
}

export interface AppError {
    isAppError: true;
    errorCode?: ErrorCode;
    message: string;
    data?: any;
}

export function interpretError(error: unknown): AppError {
    if (isAxiosError(error)) {
        if (error.response?.data) {
            const {error_code, message, data} = error.response.data;
            return {
                isAppError: true,
                errorCode: getErrorCode(error_code),
                message: message,
                data: data,
            };
        } else {
            return {isAppError: true, message: error.message};
        }
    }
    console.error("Unknown error: ", error);
    return {isAppError: true, message: "An unknown error has occurred"};
}

export function isAppError(x: any): x is AppError {
    return !!x && typeof x === "object" && x.isAppError;
}