import "./MobileMenuContainer.scss";
import {useDispatch, useSelector} from "react-redux";
import {selectNoteSlice, setMenuOpen} from "../../state/note-slice";
import classNames from "classnames";
import React from "react";
import Menu from "./Menu";
import {AppDispatch} from "../../state/store";

export function MobileMenuContainer() {
    const dispatch = useDispatch() as AppDispatch;
    const store = useSelector(selectNoteSlice);

    return <div className={classNames("MobileMenuContainer", {"visible": store.isMenuOpen})}
                onClick={() => dispatch(setMenuOpen(false))}>
        <Menu/>
    </div>;
}