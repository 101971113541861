import "./SharePage.scss";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {enderpad, SharedNote} from "../api";
import {AppError, interpretError} from "../util/error";
import {SpinnerOverlay} from "../components/SpinnerOverlay";
import {ErrorOverlay} from "../components/ErrorOverlay";
import {Base64} from "js-base64";

export function SharePage() {
    const {shareCode} = useParams() as {shareCode: string};

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<AppError | null>(null);
    const [note, setNote] = useState<SharedNote>();
    useEffect(() => {
        setLoading(true);
        setNote(undefined);
        setError(null);
        enderpad.getSharedNote(shareCode)
            .then(note => setNote(note))
            .catch(e => setError(interpretError(e)))
            .finally(() => setLoading(false));
    }, [shareCode]);

    return <div className="SharePage">
        {note?.content ? Base64.decode(note.content) : null}
        {loading && <SpinnerOverlay/>}
        {error && <ErrorOverlay>{error.message}</ErrorOverlay>}
    </div>;
}