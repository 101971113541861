import "./FormView.scss";
import React, {FormEvent} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons/faXmark";
import classNames from "classnames";

export interface FormViewProps {
    title: string;
    canSubmit?: boolean;
    onSubmit?: () => void;
    onClose?: () => void;
    buttons?: React.ReactNode;
    children?: React.ReactNode;
    className?: string;
}

export function FormView(props: FormViewProps) {
    const onSubmit = (e: FormEvent) => {
        e.stopPropagation();
        e.preventDefault();
        props.onSubmit?.();
    };

    return <form className={classNames("FormView", props.className)} onSubmit={onSubmit}>
        <div className="FormView-header">
            <span className="label">{props.title}</span>
            {props.onClose ?
                <FontAwesomeIcon icon={faXmark}
                                 size={"2x"}
                                 style={{cursor: "pointer"}}
                                 onClick={props.onClose}/>
                : null}
        </div>
        <div className="FormView-body">
            {props.children}
        </div>
        <div className="FormView-buttons">
            {props.buttons}
            <input type="submit" hidden disabled={props.canSubmit === false}/>
        </div>
    </form>;
}