import "./Menu.scss";
import React from "react";
import classNames from "classnames";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch} from "../../state/store";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRightArrowLeft} from "@fortawesome/free-solid-svg-icons/faArrowRightArrowLeft";
import {faRightFromBracket} from "@fortawesome/free-solid-svg-icons/faRightFromBracket";
import {compareDateTimeStr, goToNote, isDateTimeInPast, isNewNote} from "../../util/util";
import {TileButton} from "../../components/TileButton";
import {
    getLastSeenNote,
    logout,
    selectIsNotePersisted,
    selectNoteSlice,
    selectShouldSave,
    setLoginOpen,
    setMenuOpen
} from "../../state/note-slice";
import {faRightToBracket} from "@fortawesome/free-solid-svg-icons/faRightToBracket";
import {faThumbtack} from "@fortawesome/free-solid-svg-icons/faThumbtack";
import {faLock} from "@fortawesome/free-solid-svg-icons/faLock";

export default function Menu() {
    const navigate = useNavigate();
    const dispatch = useDispatch() as AppDispatch;

    const store = useSelector(selectNoteSlice);
    const isNotePersisted = useSelector(selectIsNotePersisted);
    const shouldSave = useSelector(selectShouldSave);

    const hasValidSession = store.session && !isDateTimeInPast(store.session.expires);

    const onLogin = () => {
        dispatch(setLoginOpen(true));
    };

    const switchSpace = async () => {
        if (store.space === "public") {
            const space = store.session!.username;
            await goToNote(navigate, dispatch, shouldSave, space, getLastSeenNote(space));
        } else {
            await goToNote(navigate, dispatch, shouldSave, "public", getLastSeenNote("public"));
        }
    };

    const onLogout = async () => {
        await goToNote(navigate, dispatch, shouldSave, "public", getLastSeenNote("public"));
        dispatch(logout());
    };

    // compile list of notes
    const notes: NoteEntryProps[] = [...(store.notes || [])].map(n => ({
        noteId: n.id,
        name: n.name,
        isActive: n.id === store.noteId,
        pinned: n.pinned,
        encrypted: n.encrypted,
        updated: n.updated,
    }));
    if (!isNotePersisted && !isNewNote(store.note.name, store.note.content)) {
        notes.push({
            noteId: store.noteId,
            name: store.note.name,
            isActive: true,
            pinned: false,
            encrypted: false,
            updated: new Date().toISOString(),
        });
    }
    notes.sort((a, b) => {
        if (a.pinned && b.pinned) return a.name.localeCompare(b.name);
        else if (a.pinned) return -1;
        else if (b.pinned) return 1;
        else return compareDateTimeStr(a.updated, b.updated) * -1;
    });

    return <div className="Menu" onClick={e => e.stopPropagation()}>
        <div className="menu-header">
            <div className="space-label">{store.space}</div>
            {hasValidSession && <>
                <TileButton className="header-button" title="Switch space" onClick={switchSpace}>
                    <FontAwesomeIcon icon={faArrowRightArrowLeft} size={"xl"} fixedWidth className="header-icon"/>
                </TileButton>
                <TileButton className="header-button" title="Logout" onClick={onLogout}>
                    <FontAwesomeIcon icon={faRightFromBracket} size={"xl"} fixedWidth className="header-icon"/>
                </TileButton>
            </>}
            {!hasValidSession &&
                <TileButton className="header-button" title="Login" onClick={onLogin}>
                    <FontAwesomeIcon icon={faRightToBracket} size={"xl"} fixedWidth className="header-icon"/>
                </TileButton>
            }
        </div>
        <div className="menu-body">
            {notes.map(note => <NoteEntry key={note.noteId} {...note}/>)}
        </div>
    </div>;
}

interface NoteEntryProps {
    noteId: string;
    name: string;
    isActive: boolean;
    pinned: boolean;
    encrypted: boolean;
    updated: string;
}

function NoteEntry(props: NoteEntryProps) {
    const {noteId, name, isActive, pinned, encrypted} = props;

    const navigate = useNavigate();
    const dispatch = useDispatch() as AppDispatch;
    const store = useSelector(selectNoteSlice);
    const shouldSave = useSelector(selectShouldSave);

    const go = async () => {
        if (!isActive) {
            await goToNote(navigate, dispatch, shouldSave, store.space, noteId);
            dispatch(setMenuOpen(false));
        }
    };

    return <TileButton className={classNames("NoteEntry", {active: isActive})} title={name} onClick={go}>
        <span className="label">{isActive && store.isNoteLoaded ? store.note.name : name}</span>
        <span className="icons">
            {pinned && <FontAwesomeIcon icon={faThumbtack} size="1x" className="icon" fixedWidth/>}
            {encrypted && <FontAwesomeIcon icon={faLock} size="1x" className="icon" fixedWidth/>}
        </span>
    </TileButton>;
}
