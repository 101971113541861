import "./NoteActions.scss";
import {faFileCirclePlus} from "@fortawesome/free-solid-svg-icons/faFileCirclePlus";
import {goToNote} from "../../util/util";
import {faCopy} from "@fortawesome/free-solid-svg-icons/faCopy";
import {faShareNodes} from "@fortawesome/free-solid-svg-icons/faShareNodes";
import {faGear} from "@fortawesome/free-solid-svg-icons/faGear";
import {
    saveNote,
    selectIsAuthorized, selectIsNotePersisted,
    selectNoteSlice, selectShouldSave,
    setDeleteNoteConfirmationOpen,
    setSettingsOpen, shareNote
} from "../../state/note-slice";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import React from "react";
import {TileButton} from "../../components/TileButton";
import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch} from "../../state/store";
import {toast} from "react-toastify";
import {Button} from "../../components/Button";

export function NoteActions() {
    const navigate = useNavigate();
    const dispatch = useDispatch() as AppDispatch;

    const store = useSelector(selectNoteSlice);
    const isAuthorized = useSelector(selectIsAuthorized);
    const shouldSave = useSelector(selectShouldSave);
    const isNotePersisted = useSelector(selectIsNotePersisted);

    const onCopy = async () => {
        await navigator.clipboard.writeText(store.note.content);
        toast("Copied!", {type: "success", autoClose: 1000});
    };

    const onShare = async () => {
        if (shouldSave) {
            await dispatch(saveNote);
        }

        const result = await dispatch(shareNote());
        if (result.type === "note/shareNote/fulfilled") {
            const sharedCode = result.payload as string;
            try {
                await window.navigator.clipboard.writeText(window.origin + "/#/share/" + sharedCode);
                toast("Share link copied!", {type: "success", autoClose: 1000});
            } catch (e: any) {
                console.warn(e?.message);

                // fallback to toast
                const onClick = () => {
                    toast.dismiss();
                    window.navigator.clipboard.writeText(window.origin + "/#/share/" + sharedCode)
                        .then(() => console.debug("Successfully wrote share link to clipboard"))
                        .catch(e => toast.error("Unable to write to clipboard: " + e?.message));
                };
                toast.success(<div className="ShareLinkToast">
                    <span>Success!</span>
                    <Button primary onClick={onClick}>COPY LINK</Button>
                </div>, {
                    toastId: "shareCode",
                    closeButton: false,
                    autoClose: false,
                });
            }
        }
    };

    return <div className="NoteActions">
        <NoteActionButton icon={faFileCirclePlus} onClick={() => goToNote(navigate, dispatch, shouldSave, store.space)}/>
        <NoteActionButton icon={faCopy} disabled={!isAuthorized || !store.isNoteLoaded} onClick={onCopy}/>
        <NoteActionButton icon={faShareNodes} disabled={!isAuthorized || !store.isNoteLoaded || !isNotePersisted} onClick={onShare}/>
        <NoteActionButton icon={faGear} disabled={!isAuthorized || !store.isNoteLoaded} onClick={() => dispatch(setSettingsOpen(true))}/>
        <NoteActionButton icon={faTrash} disabled={!isAuthorized || !isNotePersisted} onClick={() => dispatch(setDeleteNoteConfirmationOpen(true))}/>
    </div>;
}


interface FooterButtonProps {
    icon: IconProp,
    onClick: () => void;
    disabled?: boolean;
}

function NoteActionButton({icon, onClick, disabled}: FooterButtonProps) {
    return <TileButton className={classNames("NoteActionButton", {disabled})}
                       onClick={disabled ? undefined : onClick}
                       disabled={disabled}>
        <FontAwesomeIcon icon={icon} size={"xl"} fixedWidth/>
    </TileButton>;
}