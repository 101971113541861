import "./Modal.scss";
import React, {useRef} from "react";
import classNames from "classnames";

export interface ModalProps {
    open: boolean,
    onClickOutside?: () => void,
    children?: React.ReactNode,
}

export function Modal(props: ModalProps) {
    const ref = useRef<HTMLDivElement>(null);

    const onClickOutside = (e: EventTarget) => {
        if (e === ref.current) {
            props.onClickOutside?.();
        }
    };

    return <div ref={ref}
                className={classNames("Modal", {open: props.open})}
                onMouseDown={e => onClickOutside(e.target)}>
        <div className="ModalBody">
            {props.children}
        </div>
    </div>;
}