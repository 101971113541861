import "./Button.scss";
import {HTMLAttributes, useRef} from "react";
import classNames from "classnames";
import {useRipple} from "../util/ripple";

export interface ButtonProps extends HTMLAttributes<HTMLDivElement> {
    primary?: boolean,
    disabled?: boolean,
}

export function Button(props: ButtonProps) {
    const {primary, disabled, ...divProps} = props;
    const ref = useRef<HTMLDivElement>(null);
    const ripple = useRipple(ref);
    return <div {...divProps} ref={ref} className={classNames("Button", {primary, disabled}, props.className)}>
        {divProps.children}
        {!disabled && ripple}
    </div>;
}