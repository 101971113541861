import "./Switch.scss";
import classNames from "classnames";

export interface SwitchProps {
    value?: boolean,
    setValue?: (newVal: boolean) => void,
    className?: string,

}

export function Switch({value, setValue, className}: SwitchProps) {
    return <div className={classNames("Switch", {active: value}, className)} onClick={() => setValue?.(!value)}>
        <span className="handle"/>
    </div>;
}